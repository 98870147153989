<template>
<div class="organization-index">
  <el-page-header @back="goBack" :content="title"></el-page-header>
  <div class="content" v-loading="loading">
    <h2 class="content-title">基本信息</h2>
    <el-form label-position="right" label-width="140px" :rules="rules" ref="ruleForm" class="form" :model="ruleForm" v-if="type == 'add'">
      <el-form-item label="机构名称：" prop="name">
        <el-input v-model="ruleForm.name" placeholder="输入机构名称"></el-input>
      </el-form-item>
      <el-form-item label="机构城市：">
        <el-select class="select" @change="getCity" v-model="ruleForm.value1" placeholder="请选择所属省">
          <el-option v-for="item in options1" :key="item.code" :label="item.text" :value="item.code">
          </el-option>
        </el-select>
        <el-select class="select" @change="getArea" v-model="ruleForm.value2" placeholder="请选择所属市">
          <el-option v-for="item in options2" :key="item.code" :label="item.text" :value="item.code">
          </el-option>
        </el-select>
        <el-select class="select" v-model="ruleForm.value3" placeholder="请选择所属区/县">
          <el-option v-for="item in options3" :key="item.code" :label="item.text" :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="详细地址：" prop="address">
        <el-input v-model="ruleForm.address" placeholder="输入机构详细地址信息"></el-input>
      </el-form-item>
      <el-form-item label="机构状态：" prop="status">
        <el-switch v-model="ruleForm.status" active-text="开启" inactive-text="关闭"></el-switch>
      </el-form-item>
      <el-form-item label="是否为测试机构：" prop="status">
        <el-switch v-model="ruleForm.is_test" active-text="是" inactive-text="否"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="submitLoading" @click="submitForm" :disabled="canSubmit">{{type=='add'?'立即创建':'保存'}}</el-button>
      </el-form-item>
    </el-form>
    <el-form label-position="right" label-width="170px" class="form" v-if="type == 'edit'">
      <el-form-item style="margin-bottom: 10px" label="机构编号：">
        {{ruleForm.organ_code}}
      </el-form-item>
      <el-form-item label="机构名称：" prop="name">
        <el-input v-model="ruleForm.name" placeholder="输入机构名称"></el-input>
      </el-form-item>
      <el-form-item label="机构所在省市区：">
        <el-select class="select" @change="getCity" v-model="ruleForm.value1" placeholder="请选择所属省">
          <el-option v-for="item in options1" :key="item.code" :label="item.text" :value="item.code">
          </el-option>
        </el-select>
        <el-select class="select" @change="getArea" v-model="ruleForm.value2" placeholder="请选择所属市">
          <el-option v-for="item in options2" :key="item.code" :label="item.text" :value="item.code">
          </el-option>
        </el-select>
        <el-select class="select" v-model="ruleForm.value3" placeholder="请选择所属区/县">
          <el-option v-for="item in options3" :key="item.code" :label="item.text" :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="详细地址：" prop="address">
        <el-input v-model="ruleForm.address" placeholder="输入机构详细地址信息"></el-input>
      </el-form-item>
      <el-form-item label="机构状态：" prop="status">
        <el-switch v-model="ruleForm.status" active-text="开启" inactive-text="关闭"></el-switch>
      </el-form-item>
      <el-form-item label="机构成立时间：">
        <el-select class="input" v-model="ruleForm.start_year" placeholder="请选择">
          <el-option v-for="item in year_arr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="机构举办性质：">
        <el-select class="input" v-model="ruleForm.type" placeholder="请选择">
          <el-option v-for="item in type_arr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="举办者身份：">
        <el-select class="input" v-model="ruleForm.user_type" placeholder="请选择">
          <el-option v-for="item in user_type_arr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最大可收托婴幼儿总数：">
        <el-select class="input" v-model="ruleForm.child_num" placeholder="请选择">
          <el-option v-for="item in child_num_arr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开设的班型：">
        <el-select class="input" v-model="ruleForm.class_type" multiple placeholder="请选择">
          <el-option v-for="item in class_type_arr" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="托育幼儿的最小月龄">
        <el-input-number v-model="ruleForm.min_age"></el-input-number>
      </el-form-item>
      <el-form-item label="上传机构视频：">
        <div class="upload" @click="setUploadIndex('video1')">
          <h3>1）请上传托育机构周边环境视频<span>（可上传1个视频，支持MP4、MOV格式）</span></h3>
          <ul class="el-upload-list el-upload-list--picture-card" v-if="ruleForm.video1">
            <li class="el-upload-list__item is-success">
              <div style="display: flex;align-items: center;justify-content: center;">
                <video :src="ruleForm.video1.url" style="width: 100%"></video>
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video1)">
                    <i class="el-icon-video-play"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="ObsDelete('video1')">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </li>
          </ul>
          <ul class="el-upload-list el-upload-list--picture-card" v-show="uploadShow1">
            <li class="el-upload-list__item upload-item" v-loading="true" element-loading-text="上传中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
            </li>
          </ul>
          <el-upload v-if="!ruleForm.video1 && !uploadShow1" action="#" list-type="picture-card" accept="video/*" :show-file-list="false" :http-request="ObsUpload">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="upload" @click="setUploadIndex('video2')">
          <h3>2）请上传托育机构整体外观视频（如园门、建筑物等）<span>（可上传1个视频，支持MP4、MOV格式）</span></h3>
          <ul class="el-upload-list el-upload-list--picture-card" v-if="ruleForm.video2">
            <li class="el-upload-list__item is-success">
              <div style="display: flex;align-items: center;justify-content: center;">
                <video :src="ruleForm.video2.url" style="width: 100%"></video>
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video2)">
                    <i class="el-icon-video-play"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="ObsDelete('video2')">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </li>
          </ul>
          <ul class="el-upload-list el-upload-list--picture-card" v-show="uploadShow2">
            <li class="el-upload-list__item upload-item" v-loading="true" element-loading-text="上传中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
            </li>
          </ul>
          <el-upload v-if="!ruleForm.video2 && !uploadShow2" action="#" list-type="picture-card" accept="video/*" :show-file-list="false" :http-request="ObsUpload">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="upload" @click="setUploadIndex('video3')">
          <h3>3）请上传托育机构内部环境视频<span>（可上传1个视频，支持MP4、MOV格式）</span></h3>
          <ul class="el-upload-list el-upload-list--picture-card" v-if="ruleForm.video3">
            <li class="el-upload-list__item is-success">
              <div style="display: flex;align-items: center;justify-content: center;">
                <video :src="ruleForm.video3.url" style="width: 100%"></video>
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video3)">
                    <i class="el-icon-video-play"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="ObsDelete('video3')">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </li>
          </ul>
          <ul class="el-upload-list el-upload-list--picture-card" v-show="uploadShow3">
            <li class="el-upload-list__item upload-item" v-loading="true" element-loading-text="上传中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
            </li>
          </ul>
          <el-upload v-if="!ruleForm.video3 && !uploadShow3" action="#" list-type="picture-card" accept="video/*" :show-file-list="false" :http-request="ObsUpload">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="upload" @click="setUploadIndex('video4')">
          <h3>4）请上传托育机构某个班级的布局和环境（正在进行师幼互动）的视频<span>（可上传1个视频，支持MP4、MOV格式）</span></h3>
          <ul class="el-upload-list el-upload-list--picture-card" v-if="ruleForm.video4">
            <li class="el-upload-list__item is-success">
              <div style="display: flex;align-items: center;justify-content: center;">
                <video :src="ruleForm.video4.url" style="width: 100%"></video>
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video4)">
                    <i class="el-icon-video-play"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="ObsDelete('video4')">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </li>
          </ul>
          <ul class="el-upload-list el-upload-list--picture-card" v-show="uploadShow4">
            <li class="el-upload-list__item upload-item" v-loading="true" element-loading-text="上传中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)">
            </li>
          </ul>
          <el-upload v-if="!ruleForm.video4 && !uploadShow4" action="#" list-type="picture-card" accept="video/*" :show-file-list="false" :http-request="ObsUpload">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 220px;" type="primary" @click="submitForm" :loading="submitLoading" :disabled="canSubmit">提交</el-button>
      </el-form-item>
    </el-form>
    <el-descriptions v-if="type == 'check'" :column="1">
      <el-descriptions-item label="机构编号">{{ruleForm.organ_code}}</el-descriptions-item>
      <el-descriptions-item label="机构名称">{{ruleForm.name}}</el-descriptions-item>
      <el-descriptions-item label="所属城市">{{ruleForm.provice}}</el-descriptions-item>
      <el-descriptions-item label="详细地址">{{ruleForm.address}}</el-descriptions-item>
      <el-descriptions-item label="机构状态">
        <el-tag size="small" :type="(this.ruleForm.status == 1) ? '' : 'info'" effect="plain">
          {{ (this.ruleForm.status == 1) ? '开启' :'关闭'}}
        </el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="机构举办性质">{{organization_data.type_name}}</el-descriptions-item>
      <el-descriptions-item label="最大可收托婴幼儿总数">{{organization_data.child_num_name}}</el-descriptions-item>
      <el-descriptions-item label="开设的班型">{{organization_data.class_type_name}}</el-descriptions-item>
      <el-descriptions-item label="托育幼儿的最小年龄">{{organization_data.min_age}}月龄</el-descriptions-item>
      <el-descriptions-item label="上传机构视频">
        <div class="video-list">
          <div>
            <p>周边环境视频</p>
            <ul class="el-upload-list el-upload-list--picture-card">
              <li class="el-upload-list__item is-success">
                <div style="display: flex;align-items: center;justify-content: center;">
                  <video :src="ruleForm.video1.url" style="width: 100%"></video>
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video1)">
                      <i class="el-icon-video-play"></i>
                    </span>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <p>整体外观视频</p>
            <ul class="el-upload-list el-upload-list--picture-card">
              <li class="el-upload-list__item is-success">
                <div style="display: flex;align-items: center;justify-content: center;">
                  <video :src="ruleForm.video2.url" style="width: 100%"></video>
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video2)">
                      <i class="el-icon-video-play"></i>
                    </span>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <p>内部环境视频</p>
            <ul class="el-upload-list el-upload-list--picture-card">
              <li class="el-upload-list__item is-success">
                <div style="display: flex;align-items: center;justify-content: center;">
                  <video :src="ruleForm.video3.url" style="width: 100%"></video>
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video3)">
                      <i class="el-icon-video-play"></i>
                    </span>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <p>班级布局视频</p>
            <ul class="el-upload-list el-upload-list--picture-card">
              <li class="el-upload-list__item is-success">
                <div style="display: flex;align-items: center;justify-content: center;">
                  <video :src="ruleForm.video4.url" style="width: 100%"></video>
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(ruleForm.video4)">
                      <i class="el-icon-video-play"></i>
                    </span>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="创建时间">{{ruleForm.created_at}}</el-descriptions-item>
      <el-descriptions-item label="创建人">{{ruleForm.user_name}}</el-descriptions-item>
      <el-descriptions-item label="修改时间">{{ruleForm.updated_at}}</el-descriptions-item>
      <el-descriptions-item label="修改人">{{ruleForm.up_user_name}}</el-descriptions-item>
    </el-descriptions>
  </div>
  <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="70%"  @close="dialogVideoUrl = ''">
    <video class="video" controls v-if="dialogVideoUrl" width="100%" :src="dialogVideoUrl" alt="" />
  </el-dialog>
</div>
</template>

<script>
import obs from "@/utils/obs.js";

export default {
  name: "inspect-index",
  data() {
    return {
      id: "",
      title: "",
      type: '',
      ruleForm: {
        id: '',
        organ_code: '',
        min_age: 0,
        name: "",
        value1: "",
        value2: "",
        value3: "",
        address: "",
        status: true,
        video1: '',
        video2: '',
        video3: '',
        video4: '',
        class_type: [],
        child_num: '',
        user_type: '',
        type: '',
        start_year: '',
        is_test: false
      },
      rules: {
        name: [{
          required: true,
          message: "请输入机构名称",
          trigger: "blur"
        }],
      },
      class_type_arr: [],
      child_num_arr: [],
      user_type_arr: [],
      year_arr: [],
      type_arr: [],
      uploadIndex: '',
      organization_data: '',
      uploadShow1: false,
      uploadShow2: false,
      uploadShow3: false,
      uploadShow4: false,
      options1: [

      ],
      options2: [

      ],
      options3: [

      ],
      loading: false,
      createLoading: false,
      currentPage: 1,
      submitLoading: false,
      role: '',
      dialogVisible: false,
      dialogTitle: '',
      dialogVideoUrl: '',
    };
  },
  computed: {
    canSubmit() {
      return !(this.ruleForm.name && this.ruleForm.value1 && this.ruleForm.value2 && this.ruleForm.value3);
    },
  },
  mounted() {
    let r = this.$route;
    let t = r.params.type;
    let id = r.query.id;
    this.type = t;
    if (t == "add") {
      this.title = "创建机构";
    } else if (t == "edit") {
      this.title = "编辑机构";
      this.id = id;
    } else if (t == "check") {
      this.title = "查看机构";
      this.id = id;
    }
    this.init();
    let year = new Date().getFullYear() + 1;
    for (let index = year; index >= year - 50; index--) {
      this.year_arr.push({
        lable: index + '',
        value: index + ''
      })
    }
    this.role = localStorage.getItem('role');
  },
  methods: {
    setUploadIndex(index) {
      this.uploadIndex = index;
    },
    getFileDate() {
      let date = new Date();
      return date.getFullYear() + '_' + (date.getMonth() + 1) + '_' + date.getDate() + '_' + date.getTime()
    },
    async ObsUpload(e) {
      let file = e.file;
      console.log(file, this.uploadIndex);
      if (!file.file_name) {
        file.file_name = file.name;
      }
      let show = this.uploadIndex.replace('video', 'uploadShow');
      this[show] = true;
      let pahtName = `tongliao_organization/organization_${this.id}`;
      let bucketName = 'servicetuoyusaas';
      let fileName = pahtName + '/' + this.getFileDate() + '_' + file.file_name;
      let res = await obs.upload(file, fileName, bucketName);
      if (res == "success") {
        this.$message({
          message: '保存成功',
          type: 'success'
        });;
        try {
          let url =
            "https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/" + fileName;
          // console.log(url);
          this[show] = false;
          this.ruleForm[this.uploadIndex] = {
            api_url: fileName,
            file_name: file.file_name,
            url: url
          };
          return true;
        } catch (error) {
          console.log(error);
        }
      }
      console.log(list);
    },
    ObsDelete(index) {
      this.ruleForm[index] = '';
    },
    handlePictureCardPreview(file) {
      this.dialogVideoUrl = file.url;
      this.dialogVisible = true;
      this.dialogTitle = ' 查看';
    },
    async init() {
      this.loading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/province",
      });
      if (res && res.code == 0) {
        this.options1 = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
      if (this.id) {
        let res = await this.$api({
          method: "get",
          url: "/api/organ/detial",
          data: {
            id: this.id
          }
        });
        if (res.code == 0) {
          console.log(res)
          await this.getCity(res.data.province_code + '');
          await this.getArea(res.data.city_code + '');
          let item = res.data;
          this.ruleForm.name = item.name;
          this.ruleForm.value1 = item.province_code + '';
          this.ruleForm.value2 = item.city_code + '';
          this.ruleForm.value3 = item.area_code + '';
          this.ruleForm.address = item.address;
          this.ruleForm.status = item.status == 1 ? true : false;
          this.ruleForm.created_at = item.created_at;
          this.ruleForm.updated_at = item.updated_at;
          this.ruleForm.min_age = item.min_age * 1;
          this.ruleForm.provice = item.province_name + '-' + item.city_name + '-' + item.area_name;
          this.ruleForm.id = item.id;
          this.ruleForm.up_user_name = item.up_user_name;
          this.ruleForm.user_name = item.user_name;
          this.ruleForm.organ_code = item.organ_code
          this.ruleForm.class_type = res.data.class_type ? res.data.class_type.split(',') : [];
          this.ruleForm.child_num = res.data.child_num ? res.data.child_num + '' : '';
          this.ruleForm.user_type = res.data.user_type ? res.data.user_type + '' : '';
          this.ruleForm.type = res.data.type ? res.data.type + '' : '';
          this.ruleForm.start_year = res.data.start_year ? res.data.start_year + '' : '';
          this.organization_data = res.data;
          if (res.data.environment_url) {
            this.ruleForm.video1 = {
              api_url: res.data.environment_url,
              url: 'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/' + res.data.environment_url
            };
          }
          if (res.data.outside_url) {
            this.ruleForm.video2 = {
              api_url: res.data.outside_url,
              url: 'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/' + res.data.outside_url
            };
          }
          if (res.data.inhouse_url) {
            this.ruleForm.video3 = {
              api_url: res.data.inhouse_url,
              url: 'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/' + res.data.inhouse_url
            };
          }
          if (res.data.act_url) {
            this.ruleForm.video4 = {
              api_url: res.data.act_url,
              url: 'https://servicetuoyusaas.obs.cn-east-3.myhuaweicloud.com/' + res.data.act_url
            };
          }

          for (const key in res.data.class_type_arr) {
            let element = res.data.class_type_arr[key];
            this.class_type_arr.push({
              label: element,
              value: key
            })
          }
          for (const key in res.data.child_num_arr) {
            let element = res.data.child_num_arr[key];
            this.child_num_arr.push({
              label: element,
              value: key
            })
          }
          for (const key in res.data.user_type_arr) {
            let element = res.data.user_type_arr[key];
            this.user_type_arr.push({
              label: element,
              value: key
            })
          }
          for (const key in res.data.type_arr) {
            console.log(key)
            let element = res.data.type_arr[key];
            this.type_arr.push({
              label: element,
              value: key
            })
          }
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    async getCity(e) {
      this.ruleForm.value2 = '';
      this.ruleForm.value3 = '';
      this.options2 = [];
      this.options3 = [];
      let res = await this.$api({
        method: "get",
        url: "/api/city",
        data: {
          q: e
        }
      });
      if (res.code == 0) {
        this.options2 = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async getArea(e) {
      this.ruleForm.value3 = '';
      this.options3 = [];
      let res = await this.$api({
        method: "get",
        url: "/api/area",
        data: {
          q: e
        }
      });
      if (res.code == 0) {
        this.options3 = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async submitForm() {
      this.submitLoading = true;
      let res = await this.$api({
        method: "post",
        url: "/api/organ/save",
        data: {
          id: this.type == 'edit' ? this.id : 0,
          province_code: this.ruleForm.value1,
          city_code: this.ruleForm.value2,
          area_code: this.ruleForm.value3,
          name: this.ruleForm.name,
          address: this.ruleForm.address,
          status: this.ruleForm.status ? 1 : 0,
          start_year: this.ruleForm.start_year,
          type: this.ruleForm.type,
          min_age: this.ruleForm.min_age,
          user_type: this.ruleForm.user_type,
          child_num: this.ruleForm.child_num,
          stuser_type: this.ruleForm.user_type,
          class_type: String(this.ruleForm.class_type),
          environment_url: this.ruleForm.video1.api_url,
          outside_url: this.ruleForm.video2.api_url,
          inhouse_url: this.ruleForm.video3.api_url,
          act_url: this.ruleForm.video4.api_url,
          is_test: this.ruleForm.is_test ? 1 : 0,
        }
      });
      this.submitLoading = false;
      if (res.code == 0) {
        this.$message({message:this.type == 'add' ? '创建成功' : '保存成功',type:'success'});
        this.$router.go(-1);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    getList() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    deleteRow() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
          customClass: "customConfirm",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonClass: "cancelButtonClass",
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    resetData() {
      this.currentPage = 1;
      this.init();
    },
    create() {
      this.createLoading = true;
      setTimeout(() => {
        this.createLoading = false;
        this.$router.push("/inspect");
      }, 2000);
    },
    handleCurrentChange() {},
  },
  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
.organization-index {
  padding: 10px 4vw;
  min-height: calc(100vh - 150px);

  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }

  .video-list {
    display: flex;
    align-items: center;

    >div {
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      margin-right: 10px;

      >p {
        line-height: 30px;
        text-align: center;
        padding-right: 12px;
      }
    }
  }

  .content {
    overflow: hidden;
    width: 100%;
    background: #fff;
    margin-top: 20px;
    padding: 0 40px 60px;
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;

    .upload {
      padding-bottom: 10px;
      display: block;
      overflow: hidden;

      .upload-del {
        position: absolute;
        right: -9px;
        top: -11px;
        width: 22px;
        height: 22px;
      }

      .file-list-bg {
        width: 345px;
        background: #ffffff;
        border-radius: 6px;

        .file-item {
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #333;
          font-size: 15px;
          padding: 20px 15px;

          span {
            flex: auto;

            a {
              color: #333;
              font-size: 15px;
              cursor: pointer;
              text-decoration: none;
            }
          }

          i {
            flex: 0 0 20px;
          }
        }
      }

      .upload-group {
        overflow: hidden;
        padding: 0 15px;
        text-align: left;
      }

      h3 {
        font-size: 14px;
        color: #333;
        margin: 8px 0 8px;
        font-weight: normal;
        line-height: 1.6;
      }

      h3 span {
        font-size: 12px;
        color: #666;
        font-weight: normal;
      }
    }

    .content-title {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
    }

    .form {
      max-width: 600px;
    }

    .select {
      width: 30%;
      margin-right: 5%;
    }

    .select:last-of-type {
      margin-right: 0;
    }
  }
}
</style>

<style lang="scss">
.organization-index {
  .el-form-item__content {
    text-align: left;
  }

  .video {
    width: 100%;
    height: 450px;
    background: #000;
  }

  .el-form-item {
    margin-bottom: 22px;
  }

  .upload-item .el-loading-spinner .el-icon-loading {
    font-size: 20px;
  }

  .upload-item .el-loading-spinner .el-loading-text {
    font-size: 14px;
    text-align: center;
  }

  .el-descriptions {
    font-size: 15px;
  }

  .el-descriptions-item__label:not(.is-bordered-label) {
    width: 160px;
    justify-content: flex-end;
    color: #000;
  }

  .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
    padding-bottom: 22px;
  }

  .el-upload--picture-card,
  .el-upload-list--picture-card .el-upload-list__item {
    flex: 0 0 106px;
    width: 106px;
    height: 106px;
    display: flex;
    float: left;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}
</style>
